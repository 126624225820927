<template>

    
<div class="container">
    <nav class="bg-white border-gray-200 dark:bg-gray-900">
  <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
  <a href="https://flowbite.com/" class="flex items-start">
      <img src="../assets/glitzy-logo.png" class="h-20  md:h-24 md:ml-52" alt="Flowbite Logo" />
     
  </a>
  <div class="flex md:order-2">
      
      <button  type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"  class="inline-flex items-center p-0 w-12 h-12 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
        
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
        </svg>
    </button>
  </div>

  <div class="items-center  hidden w-full md:flex md:w-auto md:order-1" id="navbar-cta">
    <ul class="flex flex-col font-medium p-4   sm:mr-2  md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
     
      <li>
        <button href="#" class="block py-2 pl-3 pr-4   text-white font-bold bg-fuchsia-900 rounded md:bg-transparent md:text-fuchsia-900 md:p-0 md:dark:text-blue-500" aria-current="page" @click="scrollInto('inicio')" >INICIO</button>
      </li>
      <li>
        <button  href="#" class="block py-2 pl-3 pr-4 text-white font-bold bg-fuchsia-900 rounded md:bg-transparent md:text-fuchsia-900 md:p-0 md:dark:text-blue-500" aria-current="page" @click="scrollInto('beneficio')">BENEFICIOS</button >
      </li>
      <li>
        <button  href="#" class="block py-2 pl-3 pr-4 text-white font-bold bg-fuchsia-900 rounded md:bg-transparent md:text-fuchsia-900 md:p-0 md:dark:text-blue-500" aria-current="page" @click="scrollInto('contacto')" >CONTACTO</button >
      </li>
      
    </ul>
  </div>


  
</div>
</nav>

</div>

   
   
      
</template>
  
  <script>
  export default {
    name: 'navbar-i',
    methods: {
    scrollInto(elementId) {
      const section = document.querySelector(`#${elementId}`);
      section.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }
}      
 
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  
  </style>
  