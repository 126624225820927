<template>
   
      
    
   <div id="contacto" class="flex justify-center items-center min-h-screen bg-gray-100 relative  ">
  <div class="w-full max-w-md p-8 bg-white rounded-lg shadow-md">
    <div class="flex items-center mb-2">
      <h2 class="text-2xl lg:text-center font-semibold">Formulario de Contacto</h2>
    </div>
    <form @submit.prevent="enviarFormulario" action="sendContact" method="POST" class="flex flex-col items-center">
      <div class="mb-4">
        <label for="nombre" class="block text-gray-700 font-medium mb-2">Nombre</label>
        <input type="text" id="nombre" name="nombre" class="w-full px-20 py-2 border rounded-lg focus:outline-none focus:border-blue-500" placeholder="Nombre completo">
      </div>
      <div class="mb-4">
        <label for="email" class="block text-gray-700 font-medium mb-2">Email</label>
        <input type="email" id="email" name="email" class="w-full px-20 py-2 border rounded-lg focus:outline-none focus:border-blue-500" placeholder="correo@example.com">
      </div>
      <div class="mb-4">
        <label for="telefono" class="block text-gray-700 font-medium mb-2">Teléfono</label>
        <input type="tel" id="telefono" name="telefono" class="w-full px-20 py-2 border rounded-lg focus:outline-none focus:border-blue-500" placeholder="Teléfono de contacto">
      </div>
      <div class="mb-4">
        <label for="mensaje" class="block text-gray-700 font-medium mb-2">Mensaje</label>
        <textarea id="mensaje" name="mensaje" rows="4" class="w-full px-24 py-2 border rounded-lg focus:outline-none focus:border-blue-500" placeholder="Escribe tu mensaje aquí"></textarea>
      </div>
      <button type="submit" class="w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600">Enviar</button>
    </form>
  </div>
</div>


    
    
               
                
</template>


<script>
import axios from "axios";
export default {
name: 'contact-i',
data() {
        return {
          text: [],
   }
  },

  methods:{


    enviarFormulario() {
      // Obtener los valores de los campos del formulario
      const nombre = document.getElementById("nombre").value;
      const email = document.getElementById("email").value;
      const mensaje = document.getElementById("mensaje").value;
      const telefono = document.getElementById("telefono").value;


      let data = new FormData();
        data.append('action', 'sendContact')
        data.append('nombre', nombre)
        data.append('email', email)
        data.append('mensaje', mensaje)
        data.append('telefono', telefono)


      // Enviar los datos al servidor PHP utilizando Axios
      axios.post('https://www.glitzyestudio.com/email.php',data,{
        headers: {
      'Content-Type': 'multipart/form-data'
   },
       
      })
      .then((response) => {
        // Manejar la respuesta del servidor si es necesario
        if(response.status == 200)

       { 
        document.getElementById("nombre").value = '';
        document.getElementById("email").value = '';
        document.getElementById("mensaje").value = '';
        document.getElementById("telefono").value = '';
        document.getElementById("text").value = '';
      }        
      })
      .catch((error) => {
        // Manejar errores si los hay
        console.error(error);
      });
    },
  
},

};



</script>


<style>     
</style>