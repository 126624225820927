<template>
   
      
    
    <footer class="bg-white rounded-lg shadow w-full h-full dark:bg-gray-900 ">
        <div class="mx-auto p-4 md:py-8">
            
            <span class="block text-sm text-gray-500 text-center dark:text-gray-400">Copyright © 2023 Glitzy | Webcam studio | By Trend Studio All rights reserved. </span>
    
        </div>
    </footer>
    
    
               
                
</template>


<script>
export default {
name: 'footer-i',


}
</script>


<style>     
</style>