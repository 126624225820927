<template>
 
 <div class="col w-full h-full"  >

   <Navbar/>

   <br>
  <hr>
  
  <div class="grid grid-cols-1 md:grid-cols-2"  id="inicio">
    
    <div class="md:col-span-0 font-sans md:ml-14  lg:relative top-48  lg:ml-52 " >

      <h1 style="color:#204757;" class=" text-center text-2xl m-6  lg:text-3xl  lg:mb-8 lg:mx-1 font-black">NOSOTROS</h1>

<p style="color: #7A7A7A;" class="flex justify-center m-1 text-1xl font-medium" >Somos la agencia de videochat Glitzy Studio, brindamos un servicio de calidad en el mundo del entretenimiento para adultos dada la experiencia que poseemos, nos dedicamos a capacitar nuestras modelos para que sean reconocidas en las plataformas y así ofrecer a sus usuarios la mejor experiencia.</p>
<br>

<p style="color: #7A7A7A;" class="flex justify-center m-1 text-1xl font-medium">Contamos con un equipo de profesionales y el respaldo de Trend Studios lo que nos permite ofrecer a las modelos el soporte y posicionamiento que necesitan.</p>

<br><br>

<button  type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" @click="scrollInto('contacto')">CONTACTO</button>

</div>
<img class=" relative   left-0 w-84 lg:w-11/12   lg:left-20 " src="./assets/inicio-1.jpg" />
</div>

<!-- franja de experiencia -->

<div class="bg-fuchsia-900 h-56 lg:h-48 lg:w-full">

  <h3 style="color:white;" class="text-center text-3xl relative  lg:text-3xl lg:relative top-6 "  >EXPERIENCIA GLITZY</h3>

  <h5 style="color:white;" class="text-center  relative top-10   lg:relative top-10 " >¿CÓMO TRABAJAREMOS? </h5>

  <p style="color:white;" class="text-center text-sm  relative top-12     lg:ml-44 lg:mr-44" >Basamos todos nuestros esfuerzos en potencializar cuentas inteligentes mediante un proceso de 
    formación que exige sólo 2 cosas, una modelo comprometida y que entienda que la base del negocio está en el tiempo que ofrecemos a los usuarios en las plataformas. </p>


    

</div>

<!-- columna de video y texto no hay video -->


<div class="grid lg:grid-cols-2" id="beneficio" >
  <div class="grid grid-cols-1">
    <!-- <img class=" relative   left-0 w-84 lg:w-11/12   lg:left-20 " src="./assets/beneficio.jpg" /> -->

    <video src="./assets/glitzy-1.mp4" controls autoplay ></video>
  </div> <!-- Columna de la imagen -->
  <div class=" text-center  lg:relative lg:top-48 lg:ml-20 lg:mr-32 lg:h-32">
    <h1 class="relative text-sky-800 lg:bottom-10 lg:text-3xl" >BENEFICIOS</h1>

    <p class="relative   lg:text-xl">Como modelo de Glitzy Studio recibirás soporte técnico durante tus transmisiones, capacitación permanente, pagos semanales y espacios de trabajo sofisticados.</p>

    </div> <!-- Columna del texto -->
</div>

<hr>

<div class="grid lg:grid-cols-2 gap-4">
  <div>
    <img src="./assets/medio-izquierda.jpg" alt="Imagen 1" class="w-full h-auto lg:relative top-24 lg:left-2 ">
  </div>
  <div>
    <img src="./assets/medio-derecha.jpg" alt="Imagen 2" class="w-full h-auto lg:relative top-24 lg:right-2 ">
  </div>
</div>





<Contact></Contact>




<Footer></Footer>
</div>



    



</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import Navbar from "@/components/Navbar.vue";
import Footer from './components/Footer.vue';
import Contact from './components/Contact.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
    Contact
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
